import React, { useContext } from 'react';
import styled from "styled-components";
import { UIContext } from './UIContext';
import { Link } from "gatsby";
import TagIcon from "../assets/icons/label-dark.svg";
import PropTypes from "prop-types";

const StyledTag = styled.li`
  list-style: none;
  /* margin: 0rem 0rem 0.4rem 0.3rem; */

  .tag {
    font-size: 12px;
    background: var(--foreground);
    border-radius: 4px;
    white-space: nowrap;
    padding: 0.2rem 0.5rem;
    &:active {
      background: var(--foregrounddark);
    }
  }

  .tagicon {
    height: 9px;
    transform: scaleX(-1);
    margin-right: 0.25rem;
  }
`;

const CategoryTag = props => {
  const { language } = useContext(UIContext); // Destructured for direct access
  const lang = language || "en"; // Fallback to "en" if language is not defined
  return (
    <StyledTag key={props.tag}>
      <Link
        className="tag"
        key={`link-${props.tag}`}
        to={`/${language}/${props.tag.toLowerCase().replace(/\s+/g, "-")}`}
      >
        <img src={TagIcon} alt="tag icon svg" className="tagicon" key={`img-${props.tag}`} />
        {props.tag}
      </Link>
    </StyledTag>
  );
}

export default React.memo(CategoryTag);

CategoryTag.propTypes = {
  language: PropTypes.string.isRequired
};