import React from "react";
import styled from "styled-components";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import H from "../../components/Headline";
import RelatedContentCardFlexBig from "../../components/RelatedContentCardFlexBig";
import { useAlternateLangs } from "../../components/Hreflangs";

const StyledMiddle = styled.div`
  h1 {
    text-align: center;
  }

  .cardcontainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  margin: 0vmin 1vmin;
  .container {
  }

  .related-content-card {
    max-width: 27%;
    margin-right: 0rem;
    margin-bottom: 2rem;
  }
  @media all and (max-width: 900px) {
    .related-content-card {
      max-width: 43%;
    }
  }

  @media all and (max-width: 500px) {
    .cardcontainer {
      flex-direction: column;
      align-items: center;
    }

    .related-content-card {
      max-width: 95%;
      min-width: 80%;
    }
  }
`;

const breadCrumbLevels = {
  Hjem: "/no",
  Tjenester: "/no/tjenester"
};

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/services"
);

const Services = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title="Frilansetjenester"
        lang="no"
        description="Frilansetjenester for Analytics, SEO, Tag Management og mer. Ta kontakt og få et tilbud!"
        alternateLangs={alternateLangs}
      />
      <MainContent maxWidth="1000">
        <StyledMiddle>
          <Breadcrumb breadCrumbLevels={breadCrumbLevels} className="breadcrumb" />
          <div className="container">
            <H as="h1">Jeg kan hjelpe deg med...</H>
            <div className="cardcontainer">
              {/* TODO: just make an object with the needed data and then loop over it */}
              <RelatedContentCardFlexBig
                imageName="code"
                title="Analytics"
                link="/no/google-analytics-konsulent"
                description="Vi vil ikke forbedre oss hvis vi ikke måler hva som skjer på nettstedet vårt."

              />
              <RelatedContentCardFlexBig
                imageName="desk"
                title="SEO"
                link="/no/seo-frilanser"
                description="Alt fra en hånd: On-Page SEO, teknisk og Off-Page SEO."
              />
              <RelatedContentCardFlexBig
                imageName="coworking"
                title="Tag Management"
                link="/no/google-tag-manager-konsulent"
                description="Jeg kan hjelpe deg med å integrere og automatisere tjenester basert på dine data."
              />
            </div>
          </div>
        </StyledMiddle>
      </MainContent>
    </React.Fragment>

  </Layout>
);

export default React.memo(Services);

