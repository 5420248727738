import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const MyImg = ({ imageName, ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: { extension: { regex: "/jpeg|jpg|png|gif/" } }
      ) {
        edges {
          node {
            name
            publicURL
            childImageSharp {
              gatsbyImageData(width: 350)
            }
          }
        }
      }
    }
 `);

  // filter for the right image
  const imageEdge = data.images.edges.find(edge => edge.node.name === imageName);

  // If the image is not found, handle the error or return null
  if (!imageEdge) {
    console.error(`Image with name ${imageName} not found.`);
    return null;
  }

  const image = imageEdge.node.childImageSharp.gatsbyImageData;

  return <GatsbyImage image={image} {...props} />;
};

export default MyImg;