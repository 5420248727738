import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import ImgFixed from "./ImgFixed";
import CategoryTag from "./CategoryTag";

const StyledRelatedContentCardFlexBig = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0rem 0.5rem 1rem 0rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  max-width: 48%;

  @media all and (max-width: 700px) {
    max-width: 100%;
  }

  .card-image {
    display: flex;
    min-height: 100%;
    min-width: 100%;
  }

  .image-wrapper {
    display: flex;
    height: 120px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
  }

  .cardbody {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 0.5rem 1rem;
    background: var(--background);
    border-top: 1px solid var(--shadow);
  }

  .card-title {
    font-size: calc(16px + 1vmin);
    padding: 0;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
    min-height: 4rem;
  }

  .date-and-tag-wrapper {
    flex: 1;
    align-items: flex-start;
    display: flex;
    margin: 1vmin 0;
  }

  .card-date {
    white-space: nowrap;
    font-size: 12px;
    color: var(--foreground);
    font-family: -apple-system, Arial, "Helvetica", "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", sans-serif;
  }

  .tagcontainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    & > li {
      margin: 0rem 0rem 0.4rem 0.3rem;
    }
  }

  .description {
    flex: 1;
  }

  .viewbutton {
    font-size: 14px;
    color: var(--primary);
    padding: 0.5rem 1rem;
    border-radius: 4px;
    align-self: flex-end;
    &:hover,
    &:focus {
      outline: 0;
      background: var(--shadow);
    }
  }
`;

const RelatedContentCardFlexBig = props => {
  return (
    <StyledRelatedContentCardFlexBig className="related-content-card">
      <Link to={props.link}>
        <div className="image-wrapper">
          <ImgFixed imageName={props.imageName} className="card-image" />

          {/* <Img src={props.relativePath} className="card-image" /> */}
        </div>
      </Link>

      <div className="cardbody">
        <Link to={props.link}>
          <h3 className="card-title">{props.title}</h3>
        </Link>
        {props.date &&
          (props.tags && (
            <div className="date-and-tag-wrapper">
              {props.date && <span className="card-date">{props.date}</span>}
              {props.tags && (
                <div className="tagcontainer">
                  {props.tags
                    .sort((a, b) => a.length - b.length)
                    .map(tag => <CategoryTag tag={tag} language={props.language} key={tag} />)}
                </div>
              )}
            </div>
          ))}

        {/* 
        taking the description out
        {props.description && (
          <div className="description">{props.description}</div>
        )} */}
        {/* <Link className="viewbutton" to={props.link}>
          VIEW
        </Link> */}
      </div>
    </StyledRelatedContentCardFlexBig>
  );
};

export default React.memo(RelatedContentCardFlexBig);
